import request from '../../utils/request'

//根据查询条件获取运维列表
export function operationList(hotelId,gwStatus,limit,page,roomNumber,token) {
    return request({
        url:"/hotel/devOps/deviceDevOps",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
            gwStatus:gwStatus,
            limit:limit,
            page:page,
            roomNumber:roomNumber,
        }
    })
}
//根据查询条件酒店设备详情
export function getDetail(roomId,deviceStatus,token) {
    return request({
        url:"/hotel/devOps/deviceDevOpsDetails",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            roomId:roomId,
            deviceStatus:deviceStatus,
        }
    })
}
